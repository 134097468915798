<template>
    <v-container
    id="allowance-summary"
    fluid
    tag="section"
  >
      <v-alert
      dense
      text
      type="success"
      dismissible
      v-model="alert"
    >
      Allowance Summary <strong>saved</strong> successfully.
    </v-alert>
    <v-row justify="center">
        <v-col
        cols="12"
        md="12"
        >
            <base-material-card>
            <template v-slot:heading>
                <div class="display-2 font-weight-light">
                Allowance Summary
                </div>
            </template>
            <v-form>
                <v-container class="py-0">
                    <v-row>
                        <v-col
                        cols="12"
                        md="6"
                        >
                            <v-text-field
                                label="Owner 1 - First Name"
                                :rules="[rules.required]"
                                v-model="selected_user.PrimaryFirstName"
                            />
                        </v-col>
                        <v-col
                        cols="12"
                        md="6"
                        >
                            <v-text-field
                                label="Owner 1 - Last Name"
                                :rules="[rules.required]"
                                v-model="selected_user.PrimaryLastName"
                            />
                        </v-col>
                        <v-col
                        cols="12"
                        md="6"
                        >
                            <v-text-field
                                label="Owner 2 - First Name"
                                :rules="[rules.required]"
                                v-model="selected_user.SecondaryFirstName"
                            />
                        </v-col>
                        <v-col
                        cols="12"
                        md="6"
                        >
                            <v-text-field
                                label="Owner 2 - Last Name"
                                :rules="[rules.required]"
                                v-model="selected_user.SecondaryLastName"
                            />
                        </v-col>

                        <v-col cols="12">
                            <v-card>
                                <v-card-text>
                                    <base-subheading subheading="FLOORING" />
                                        <v-row>
                                            <v-col cols="6" md="3" align="center">
                                                Vinyl
                                            </v-col>
                                            <v-col cols="6" md="3">
                                                <v-text-field
                                                    label="Amount"
                                                    value="2.27.00"
                                                    prefix="$"
                                                    suffix="Sq.Ft."
                                                    v-model="section.column_5"
                                                ></v-text-field>
                                            </v-col>
                                           
                                            <v-col cols="6" md="3" align="center">
                                                Carpet
                                            </v-col>
                                            <v-col cols="6" md="3">
                                                <v-text-field
                                                    label="Amount"
                                                    value="2.27.00"
                                                    prefix="$"
                                                    suffix="Sq.Ft."
                                                    v-model="section.column_6"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="6" md="3" align="center">
                                                Hardwood
                                            </v-col>
                                            <v-col cols="6" md="3">
                                                <v-text-field
                                                    label="Amount"
                                                    v-model="section.column_7"
                                                ></v-text-field>
                                            </v-col>
                                           
                                            <v-col cols="6" md="3" align="center">
                                                Tile
                                            </v-col>
                                            <v-col cols="6" md="3">
                                                <v-text-field
                                                    label="Amount"
                                                    v-model="section.column_8"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col md="3" align="center">
                                                LVP
                                            </v-col>
                                            <v-col md="3">
                                                <v-text-field
                                                    label="Amount"
                                                    v-model="section.column_9"
                                                ></v-text-field>
                                            </v-col>
                                            
                                        </v-row>

                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col cols="12">
                            <v-card>
                                <v-card-text>
                                    <base-subheading subheading="BATHROOM" />
                                        <v-row>
                                            <v-col cols="6" md="3" align="center">
                                                Tile Tubs
                                            </v-col>
                                            <v-col cols="6" md="3">
                                                <v-text-field
                                                    v-model="section.column_10"
                                                ></v-text-field>
                                            </v-col>
                                           
                                            <v-col cols="6" md="3" align="center">
                                                Tile Showers
                                            </v-col>
                                            <v-col cols="6" md="3">
                                                <v-text-field
                                                    v-model="section.column_11"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col cols="12">
                            <v-card>
                                <v-card-text>
                                    <base-subheading subheading="COUNTER TOPS" />
                                        <v-row>
                                            <v-col cols="6" md="3" align="center">
                                                Kitchen
                                            </v-col>
                                            <v-col cols="6" md="3">
                                                <v-text-field
                                                    v-model="section.column_12"
                                                ></v-text-field>
                                            </v-col>
                                           
                                            <v-col cols="6" md="3" align="center">
                                                Bathrooms
                                            </v-col>
                                            <v-col cols="6" md="3">
                                                <v-text-field
                                                    v-model="section.column_13"
                                                ></v-text-field>
                                            </v-col>

                                            <v-col cols="6" md="3" align="center">
                                                Laundry Room
                                            </v-col>
                                            <v-col cols="6" md="3">
                                                <v-text-field
                                                    v-model="section.column_14"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col cols="12">
                            <v-card>
                                <v-card-text>
                                    <base-subheading subheading="MIRRORS" />
                                        <v-row>
                                            <v-col cols="6" md="3" align="center">
                                                STD. Frameless
                                            </v-col>
                                            <v-col cols="6" md="3">
                                                <v-text-field
                                                    v-model="section.column_15"
                                                ></v-text-field>
                                            </v-col>
                                           
                                            <v-col cols="6" md="3" align="center">
                                                Framed
                                            </v-col>
                                            <v-col cols="6" md="3">
                                                <v-text-field
                                                    v-model="section.column_16"
                                                ></v-text-field>
                                            </v-col>

                                            <v-col cols="6" md="3" align="center">
                                                Laundry Room
                                            </v-col>
                                            <v-col cols="6" md="3">
                                                <v-text-field
                                                    v-model="section.column_17"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col cols="12">
                            <v-card>
                                <v-card-text>
                                    <base-subheading subheading="APPLIANCES" />
                                
                                <v-col cols="12" md="12">
                                    <v-text-field
                                        v-model="section.column_18"
                                    ></v-text-field>
                                </v-col>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col cols="12">
                            <v-card>
                                <v-card-text>
                                    <base-subheading subheading="LIGHTING" />
                                
                                <v-col cols="12" md="12">
                                    <v-text-field
                                        v-model="section.column_19"
                                    ></v-text-field>
                                </v-col>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col cols="12">
                            <v-card>
                                <v-card-text>
                                    <base-subheading subheading="STONE" />
                                        <v-row>
                                            <v-col cols="6" md="3" align="center">
                                               Exterior
                                            </v-col>
                                            <v-col cols="6" md="3">
                                                <v-text-field
                                                    v-model="section.column_20"
                                                ></v-text-field>
                                            </v-col>
                                           
                                            <v-col cols="6" md="3" align="center">
                                                Interior
                                            </v-col>
                                            <v-col cols="6" md="3">
                                                <v-text-field
                                                    v-model="section.column_21"
                                                ></v-text-field>
                                            </v-col>

                                        </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col cols="12">
                            <v-card>
                                <v-card-text>
                                    <base-subheading subheading="LANDSCAPING" />
                                        <v-row>
                                            <v-col cols="6" md="3" align="center">
                                               Sod
                                            </v-col>
                                            <v-col cols="6" md="3">
                                                <v-text-field
                                                    v-model="section.column_22"
                                                ></v-text-field>
                                            </v-col>
                                           
                                            <v-col cols="6" md="3" align="center">
                                                Irrigation
                                            </v-col>
                                            <v-col cols="6" md="3">
                                                <v-text-field
                                                    v-model="section.column_23"
                                                ></v-text-field>
                                            </v-col>

                                            <v-col cols="6" md="3" align="center">
                                               Retaining Walls
                                            </v-col>
                                            <v-col cols="6" md="3">
                                                <v-text-field
                                                    v-model="section.column_24"
                                                ></v-text-field>
                                            </v-col>
                                           
                                            <v-col cols="6" md="3" align="center">
                                                Landscape Shrubs
                                            </v-col>
                                            <v-col cols="6" md="3">
                                                <v-text-field
                                                    v-model="section.column_25"
                                                ></v-text-field>
                                            </v-col>

                                        </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
            </base-material-card>

          <base-material-card>
            <template v-slot:heading>
                <div class="display-2 font-weight-light">
                Initials
                </div>
            </template>
            <v-form>
                <v-container class="py-0">
                  <v-row>

                        <v-col
                        cols="12"
                        md="4"
                        >
                            <v-text-field
                                label="Owner 1 - Initials"
                                :rules="[rules.required]"
                            />
                        </v-col>
                        <v-col
                        cols="12"
                        md="4"
                        >
                            <v-text-field
                                label="Date"
                                type="date"
                                v-model="section.column_26"
                            />
                        </v-col>
                        <v-col
                        cols="12"
                        md="4"
                        >
                        </v-col>

                        <v-col
                        cols="12"
                        md="4"
                        >
                            <v-text-field
                                label="Owner 2 - Initials"
                                :rules="[rules.required]"
                                :align-self="'start'"
                                v-model="section.column_27"

                            />
                        </v-col>
                        <v-col
                        cols="12"
                        md="4"
                        >
                            
                        </v-col>
                         <v-col
                        cols="12"
                        md="4"
                        >
                        </v-col>
                           <v-col cols="12" class="text-right">
                        <v-btn color="success" class="mr-0"
                        @click="updateSection()">Save</v-btn>
                    </v-col>
                    </v-row>
                </v-container>
            </v-form>
            </base-material-card>
        </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from "axios";

export default {
    name:'VendorSelectionsAllowanceSummary',
    data () {
        return {
        email: '',
        rules: {
            required: value => !!value || 'Required.',
            email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
            },
        },
          home_id: localStorage.getItem("homeid"),
        alert:false,
        section_id: "AS",
        section: {
          column_1:'',
          column_2:'',
          column_3:'',
          column_4:'',
          column_5:'',
          column_6:'',
          column_7:'',
          column_8:'',
          column_9:'',
          column_10:'',
          column_11:'',
          column_12:'',
          column_13:'',
          column_14:'',
          column_15:'',
          column_16:'',
          column_17:'',
          column_18:'',
          column_19:'',
          column_20:'',
          column_21:'',
          column_22:'',
          column_23:'',
          column_24:'',
          column_25:'',
          column_26:'',
          column_27:''
        },
        selected_user: {
            PrimaryEmailAddress:'',
            SecondaryEmailAddress:'',
            PrimaryFirstName: '',
            PrimaryLastName: '',
            SecondaryFirstName: '',
            SecondaryLastName: '',
            Address:'',
            City:'',
            State: '',
            Zip: '',
            BuildStart: '',
            BuildEnd:'',
            CellNumber:'',
            HomeNumber: '',
            WorkPhone:'',
            Role:'',
            Agent:''
          },
        }
    },
  created () {
    this.getSection()
    this.getUserInfo()
    },
    methods: {

      getSection() {
        axios
            .get(process.env.VUE_APP_GET_VENDOR_SELECTIONS_URL,
                {
                  params: {
                    home_id: this.home_id,
                    section_id: this.section_id
                  }
                })
            .then(response => {

              if (response.data) {
                var result = response.data
                this.section = JSON.parse(result['JsonDetails'])
              }
            })
            .catch(error => {
              console.log(error)
            })
      },
      updateSection() {
        var body = {
          'home_id': this.home_id,
          'section_id': this.section_id,
          "section": JSON.stringify(this.section),
        }
        axios
            .post(process.env.VUE_APP_UPDATE_VENDOR_SELECTIONS_URL, body)
            .then(() => {
              this.alert = true
            })
            .catch(error => {
              console.log(error)
            })
      },
      getUserInfo() {
          axios
          .get(process.env.VUE_APP_GET_USER_URL_INFO,
          {
            params: {
              home_id: this.home_id
          }
          })
          .then(response => {

              if (response.data)
              {
                  var result = response.data
                  this.selected_user = result[0]
              }
          })
          .catch(error => {
          console.log(error)
          })
        },
    }
}
</script>